import { COMPANY } from "./constants";

export const formatPhoneNumber = (phoneNumberString: string): string => {
	const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
	return cleaned.replace(/^(\d{3})(\d{3})(\d{4})$/, '$1-$2-$3');
};

export const isUninsuredPatient = (value: string): boolean => {
	return value.toLowerCase() === COMPANY.UNINSURED_PATIENT.toLowerCase()
}
