import styled from "../../ui-core/styled-components";
import KeraltyModal from "./keraltyModal";
import { Image, KeraltyButton, KeraltyText } from '../atoms';

import { Container, Row, SpaceWrapper } from "../templates";
import useIsMobile from 'app/hooks/useIsMobile';

const Icon = styled(Image)`
  width: 100%;
  height: 35px;
`;

const styleTitle = {
	textAlign: 'center',
	marginRight: 7,
	fontSize: 24,
	fontWeight: 600,
	color: '#0069A7',
};

const styleText = {
	textAlign: 'center',
	marginRight: 7,
	fontSize: 16,
	fontWeight: 400,
	color: '#212121',
};

const styleDiv = {
	padding: 0,
	margin: 0,
	flex: 1,
	justifyContent: 'center',
};

const styleButton = {
	height: 51,
	borderRadius: 40,
	color: '#FFF',
	marginRight: 10,
	marginLeft: 5,
	marginTop: 10,
	paddingTop: 0,
	paddingBottom: 0,
	marginBottom: 0,
	backgroundColor: '#3CA70D',
	border: 'none',
	borderColor: '#FFFFFF',
	fontSize: 18,
	fontFamily: 'Proxima Nova, Roboto, Lato, sans-serif',
	cursor: 'pointer',
};

const styleButton2 = {
	marginRight: 22,
	marginLeft: 5,
	marginTop: 10,
	paddingTop: 0,
	paddingBottom: 0,
	borderRadius: 40,
	height: '51px',
	fontSize: 16,
	fontWeight: 'bold',
	fontFamily: 'Proxima Nova, Roboto, Lato, sans-serif',
	borderWidth: 2,
	// width: '98px',
};

const KeraltyButtonStyle = styled(KeraltyButton)`
  font-size: 1.35rem;

  padding: 0 1rem;
`;

interface CustomModalProps {
	icon: string;
	title?: string;
	subTitle?: string;
	options: CustomModalOption[];
	id: string;
}

export interface CustomModalOption {
	text: string;
	action: () => void;
	style: OptionStyle;
}

type OptionStyle = 'lightGreen' | 'darkGreen';

const OptionStyles = {
	lightGreen: {
		backgroundColor: '#FFF',
		color: '#3CA70D',
		borderColor: '#3CA70D',
	},
	darkGreen: {
		backgroundColor: '#3CA70D',
		color: '#FFF',
		borderColor: '#3CA70D',
	},
}


export const CustomModal = ({ icon, title, subTitle, options, id }: CustomModalProps) => {

	const isMobile = useIsMobile();

	return (
		<KeraltyModal size={'extraLongEighty'} onClickContainer={''}>
			<div id={id} style={{ display: 'grid', placeItems: 'center' }}>

				<div id="header_icon">
					{ icon ? <Icon src={icon} alt={"Modal icon"} /> : '' }
				</div>

				<SpaceWrapper mb={1} style={{ paddingTop: 20 }}>
					<Container centered={'x'} noPadding noMargin>
						<KeraltyText type={'label'} style={styleTitle}>
							{ title }
						</KeraltyText>
					</Container>
				</SpaceWrapper>

				{
					subTitle &&
					(
						<SpaceWrapper mb={1} style={{ paddingTop: 15 }}>
							<Container centered={'x'} noPadding noMargin>
								<KeraltyText type={'label'} align={'center'} style={styleText}>
									{ subTitle }
								</KeraltyText>
							</Container>
						</SpaceWrapper>
					)
				}

			</div>

			<Row>

				{
					options.map((option) => (
						<div style={styleDiv}>
							<KeraltyButtonStyle
								type={'button'}
								full
								onClick={option.action}
								style={{ ...styleButton2, ...OptionStyles[option.style] }}
							>
								<text style={{ color: OptionStyles[option.style].color, fontSize: isMobile ? 14 : 18, letterSpacing: 1 }}>
									{ option.text }
								</text>
							</KeraltyButtonStyle>
						</div>
					))
				}
			</Row>
		</KeraltyModal>
	)
}
